import { Box, ListItemButton, ListItemText } from "@mui/material"
import GavelIcon from "@mui/icons-material/Gavel"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useContractList from "model/contracts/list/useContractList"
import SearchBar from "components/views/generic/SearchBar"
import CreateButton from "components/views/generic/CreateButton"
import ListDialogContent from "components/dialogs/ListDialogContent"

const ContractListDialog = ({
  teamKey,
  isOpen,
  onContractSelected,
  onCreateClicked,
}: {
  teamKey: string
  isOpen: boolean
  onContractSelected: (contractKey: string | null) => void
  onCreateClicked: () => void
}) => {
  const [viewData, input] = useContractList({ teamKey, isSelecting: true })
  const contractListItems = viewData?.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={() => {
        onContractSelected(null)
        input?.changeSearchQuery("")
      }}
      title={viewData?.title}
      hideTitleOnMobile
      barItems={
        <>
          <SearchBar
            placeholder={viewData?.searchHint}
            onChange={(text: string) => {
              input?.changeSearchQuery(text)
            }}
            autoFocus={true}
          />
          <CreateButton
            text={viewData?.createButtonText}
            onClick={onCreateClicked}
          />
        </>
      }
    >
      <ListDialogContent emptyViewData={viewData?.empty} emptyIcon={GavelIcon}>
        {contractListItems?.map((listItem) => {
          return (
            <ListItemButton
              key={listItem.contractKey}
              divider={true}
              onClick={() => {
                onContractSelected(listItem.contractKey)
              }}
            >
              <Box px={1}>
                <ListItemText>{listItem.name.text}</ListItemText>
              </Box>
            </ListItemButton>
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

export default ContractListDialog
