import { styled } from "@mui/material/styles"
import { Dialog } from "@mui/material"

import NavigationBar from "components/views/generic/NavigationBar"
import LoadingModal from "components/views/generic/LoadingModal"

const ScrollContainer = styled("div")({
  maxHeight: "60%",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
})

const NavigationBarDialog = ({
  isOpen,
  onClose,
  title,
  dynamicHeight,
  barItems,
  children,
  loadingProgress,
  hideTitleOnMobile,
}: {
  isOpen: boolean
  onClose: () => void
  title: string | null | undefined
  dynamicHeight?: boolean
  noScroll?: boolean
  barItems?: React.ReactNode
  children: React.ReactNode
  loadingProgress?: number | null
  hideTitleOnMobile?: boolean
}) => {
  return (
    <Dialog fullWidth scroll="body" open={isOpen} onClose={onClose}>
      <NavigationBar
        barItems={barItems}
        title={title}
        hideTitleOnMobile={hideTitleOnMobile}
      />
      <ScrollContainer style={dynamicHeight ? {} : { height: "60%" }}>
        {children}
      </ScrollContainer>
      <LoadingModal
        open={loadingProgress !== null && loadingProgress !== undefined}
        value={loadingProgress}
      />
    </Dialog>
  )
}

export default NavigationBarDialog
