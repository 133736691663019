import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"

import * as shared from "probuild-shared"

const RootDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
})

const NameTypography = styled(Typography)({
  fontWeight: "bold",
})

const DescriptionTypography = styled(Typography)({
  whiteSpace: "pre-line",
})

const AmountTypography = styled(Typography)({
  minWidth: 150,
  textAlign: "right",
})

const RepeatItemRow = ({
  documentAnswerKey,
  element,
  input,
}: {
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  return (
    <RootDiv>
      <div>
        <NameTypography>{element.row?.item?.nameText}</NameTypography>
        <DescriptionTypography>
          {element.row?.item?.descriptionText}
        </DescriptionTypography>
      </div>
      <AmountTypography>{element.row?.item?.valueText}</AmountTypography>
    </RootDiv>
  )
}

export default RepeatItemRow
