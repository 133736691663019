import { Box, Button, styled, Typography } from "@mui/material"
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab"

import * as shared from "probuild-shared"

import { Link } from "react-router-dom"

const DocumentInfoPreviewHistoryItemView = ({
  history,
  onResendClicked,
}: {
  history: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentInfoPreviewHistoryItemViewData
  onResendClicked: (workflowKey: string) => void
}) => {
  return (
    <StyledTimelineItem>
      <TimelineEventTitle
        eventDate={history.date}
        contentLabel={history.title}
        onResendClicked={() => {
          if (history.reportResendWorkflowKey) {
            onResendClicked(history.reportResendWorkflowKey)
          }
        }}
        resendButton={history.reportResendText || null}
      />
      {history.description && (
        <TimelineEventDetails
          eventDescription={history.description}
          reportFilename={history.reportFilename}
          reportUrl={history.reportUrl}
        />
      )}
    </StyledTimelineItem>
  )
}

const StyledTimelineItem = ({ children }: { children?: React.ReactNode }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>{children}</TimelineContent>
    </TimelineItem>
  )
}

const TimelineTitleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const TimelineEventTitle = ({
  eventDate,
  contentLabel,
  resendButton,
  onResendClicked,
}: {
  eventDate: string
  contentLabel: string
  resendButton: string | null
  onResendClicked: () => void
}) => {
  return (
    <TimelineTitleBox>
      <Box>
        <Typography fontWeight="bold">{contentLabel}</Typography>
        <Typography fontStyle="italic">{eventDate}</Typography>
      </Box>
      {resendButton && (
        <Button onClick={onResendClicked}>{resendButton}</Button>
      )}
    </TimelineTitleBox>
  )
}

const TimelineDescriptionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.main,
}))

const TimelineEventDetails = ({
  eventDescription,
  reportFilename,
  reportUrl,
}: {
  eventDescription?: string | null
  reportFilename?: string | null
  reportUrl?: string | null
}) => {
  return (
    <TimelineDescriptionBox component="blockquote">
      <Typography style={{ whiteSpace: "pre-wrap" }}>
        {eventDescription}
      </Typography>
      {reportFilename && reportUrl && (
        <Button component={Link} variant="outlined" to={reportUrl}>
          {reportFilename}
        </Button>
      )}
    </TimelineDescriptionBox>
  )
}

export default DocumentInfoPreviewHistoryItemView
