import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

import * as shared from "probuild-shared"

import ImageView from "components/views/generic/ImageView"

const StyledImageView = styled(ImageView)({
  width: "100%",
  height: "375px",
})

const LeftDiv = styled("div")(({ theme }) => ({
  width: "40%",
  marginRight: theme.spacing(1),
}))

const RightDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "leading",
  height: "100%",
  width: "60%",
  marginLeft: theme.spacing(1),
}))

const StyledBox = styled(Box)({
  textAlign: "left",
  whiteSpace: "pre-line",
})

const RootDiv = styled("div")({
  minHeight: "375px",
  display: "flex",
  width: "100%",
  alignItems: "center",
})

const RepeatItemRow = ({
  documentAnswerKey,
  element,
  input,
}: {
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  const description = element.row?.photo?.photoDescription
  const descriptionFallback = element.row?.photo?.photoDescriptionFallback
  return (
    <RootDiv>
      <LeftDiv>
        <StyledImageView
          alt={element.row?.photo?.photoDescription || ""}
          file={element.row?.photo?.photoFileData}
          width={310}
          height={375}
          cover={true}
        />
      </LeftDiv>
      <RightDiv>
        {description && <StyledBox fontStyle="normal">{description}</StyledBox>}
        {descriptionFallback && (
          <StyledBox fontStyle="italic">{descriptionFallback}</StyledBox>
        )}
      </RightDiv>
    </RootDiv>
  )
}

export default RepeatItemRow
