import LabeledSwitchView from "components/views/generic/LabeledSwitchView"

import * as shared from "probuild-shared"

const DocumentEditingSwitch = ({
  documentAnswerKey,
  element,
  input,
}: {
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  const onChange = (isOn: boolean) => {
    input.changeElementBoolean(documentAnswerKey, element.elementKey, isOn)
  }
  if (!element.toggleSwitch) return null
  return (
    <LabeledSwitchView
      labelText={element.toggleSwitch.label}
      isOn={element.toggleSwitch.value}
      onChange={onChange}
    />
  )
}

export default DocumentEditingSwitch
