import { styled } from "@mui/material/styles"
import ViewDataTextField from "components/views/generic/ViewDataTextField"

import * as shared from "probuild-shared"

const StyledTextField = styled(ViewDataTextField)({
  width: "100%",
})

const DocumentEditingText = ({
  documentAnswerKey,
  element,
  input,
  isFirst,
}: {
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
  isFirst: boolean
}) => {
  const onChange = (text: string) => {
    input.changeElementText(documentAnswerKey, element.elementKey, text)
  }
  const viewData = element.textField
  if (!viewData) return null
  return <StyledTextField viewData={viewData} onChange={onChange} />
}

export default DocumentEditingText
