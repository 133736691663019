import { Box, Chip, IconButton, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import { Delete } from "@mui/icons-material"

const RootDiv = styled("div")({
  width: "100%",
})

const StyledDiv = styled("div")({
  maxWidth: 450,
  margin: "0 auto",
})

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  minWidth: 450,
  backgroundColor: grey[100],
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    minWidth: 300,
  },
  [`&:hover`]: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: theme.spacing(1),
  },
}))

const StyledLink = styled(Link)({
  display: "flex",
  justifyContent: "center",
  color: "inherit",
  textDecoration: "inherit",
  alignSelf: "center",
  height: "fit-content",
  width: "100%",
  [`&:hover`]: {
    backgroundColor: "inherit",
    borderRadius: "inherit",
  },
})

const LeftInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  gap: theme.spacing(1),
}))

const DocumentTypeName = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}))

const StatusDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  paddingLeft: theme.spacing(0.5),
  gap: theme.spacing(1),
}))

const RightInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginLeft: "auto",
  gap: theme.spacing(1),
}))

const DeleteBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.primary.light,
  borderTopRightRadius: theme.spacing(1),
  borderBottomRightRadius: theme.spacing(1),
}))

const PostAttachmentDocumentView = ({
  teamKey,
  viewData,
  onDelete,
}: {
  teamKey: string
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentDocumentViewData
  onDelete: ((attachmentKey: string) => void) | null
}) => {
  const summary = viewData.summary
  if (summary) {
    const documentPath = paths.document(teamKey, summary.documentKey)
    return (
      <RootDiv>
        <StyledDiv>
          <StyledBox>
            <StyledLink to={documentPath}>
              <LeftInnerContainer>
                <DocumentTypeName fontWeight={"bold"}>
                  {viewData.summary?.name}
                </DocumentTypeName>
                <StatusDiv>
                  <Chip
                    size="small"
                    label={viewData.summary?.sendStatus}
                    sx={{ cursor: "pointer" }}
                  />
                  {viewData.summary?.paymentStatus && (
                    <Chip
                      size="small"
                      label={viewData.summary?.paymentStatus}
                      sx={{ cursor: "pointer" }}
                    />
                  )}
                </StatusDiv>
              </LeftInnerContainer>
              <RightInnerContainer>
                <Typography>{viewData.summary?.createdAtFormatted}</Typography>
                <Typography>{viewData.summary?.total}</Typography>
              </RightInnerContainer>

              {onDelete && (
                <DeleteBox>
                  <IconButton
                    onClick={() => onDelete(viewData.attachmentKey)}
                    sx={{ color: "white", alignSelf: "center" }}
                  >
                    <Delete />
                  </IconButton>
                </DeleteBox>
              )}
            </StyledLink>
          </StyledBox>
        </StyledDiv>
      </RootDiv>
    )
  } else {
    return <></>
  }
}

export default PostAttachmentDocumentView
