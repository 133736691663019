import { Box, Chip, Divider, ListItemButton, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import paths from "model/utils/paths"

import * as shared from "probuild-shared"
import colorToCss from "model/utils/colorToCss"
import { green } from "@mui/material/colors"
import TimelapseIcon from "@mui/icons-material/Timelapse"
import { styled } from "@mui/system"

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1),
}))

const LeftInnerColumn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: theme.spacing(0.5),
}))

const StyledDescription = styled(Typography)({
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textOverflow: "ellipsis",
})

const RightInnerColumn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  justifyContent: "space-between",
  minWidth: "fit-content",
  gap: theme.spacing(1),
}))

const StyledChipBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  gap: theme.spacing(1),
  fontWeight: "bold",
  opacity: 0.5,
}))

const UnreadPostCountChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  cursor: "pointer",
}))

const ClockedInMembersChip = styled(Chip)(({ theme }) => ({
  backgroundColor: green[500],
  color: theme.palette.common.white,
  cursor: "pointer",
  "& .MuiChip-icon": {
    color: "inherit",
  },
}))

const ProjectListItemView = ({
  teamKey,
  projectListItem,
}: {
  teamKey: string
  projectListItem: shared.com.probuildsoftware.probuild.library.projects.data.view.ProjectListItemViewData
}) => {
  const projectPath = paths.project(teamKey, projectListItem.projectKey)
  return (
    <>
      <ListItemButton
        component={Link}
        key={projectListItem.projectKey}
        to={projectPath}
      >
        <StyledBox>
          <LeftInnerColumn>
            <Typography
              sx={{
                fontSize: "large",
                fontWeight: projectListItem.unreadPostCount ? "bold" : "normal",
              }}
            >
              {projectListItem.projectName}
            </Typography>
            {projectListItem.projectDescription && (
              <StyledDescription>
                {projectListItem.projectDescription}
              </StyledDescription>
            )}
            <Typography>
              {projectListItem.clientName?.text ||
                projectListItem.clientName?.fallback}
            </Typography>
            <Typography>{projectListItem.projectMembersCount}</Typography>
          </LeftInnerColumn>
          <RightInnerColumn>
            <Typography
              sx={{
                fontWeight: projectListItem.unreadPostCount ? "bold" : "normal",
              }}
            >
              {projectListItem.lastModifiedDateFormatted}
            </Typography>
            <StyledChipBox>
              {projectListItem.unreadPostCount && (
                <UnreadPostCountChip label={projectListItem.unreadPostCount} />
              )}
              {projectListItem.clockedInMemberCount && (
                <ClockedInMembersChip
                  icon={<TimelapseIcon />}
                  label={projectListItem.clockedInMemberCount}
                />
              )}
            </StyledChipBox>
            <Chip
              variant="outlined"
              label={projectListItem.status}
              sx={{
                cursor: "pointer",
                color: colorToCss(projectListItem.statusColor),
                borderColor: colorToCss(projectListItem.statusColor),
              }}
            />
          </RightInnerColumn>
        </StyledBox>
      </ListItemButton>
      <Divider />
    </>
  )
}

export default ProjectListItemView
