import { Button, Chip, Divider, Paper, styled } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined"
import SendIcon from "@mui/icons-material/Send"
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined"

import * as shared from "probuild-shared"

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  backgroundColor: theme.palette.grey[100],
  position: "sticky",
  bottom: 0,
  padding: theme.spacing(2),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  gap: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    position: "fixed",
    left: 0,
    right: 0,
    margin: 0,
  },
}))

const StatusDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: theme.spacing(1),
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const QuickActionMenuDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  gap: theme.spacing(1),
}))

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    [`& .MuiButton-startIcon`]: {
      margin: 0,
    },
  },
}))

const DocumentPreviewToolbar = ({
  viewData,
  onInfoClicked,
  onPaymentClicked,
  onConvertToInvoiceClicked,
  onSendClicked,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentPreviewViewData | null
  onInfoClicked: () => void
  onPaymentClicked: () => void
  onConvertToInvoiceClicked: () => void
  onSendClicked: () => void
}) => {
  if (!viewData) return null
  return (
    <StyledPaper variant="outlined">
      <StatusDiv>
        <Chip label={viewData.sendStatus} onClick={onInfoClicked} />
        {viewData.paymentStatus && (
          <Chip label={viewData.paymentStatus} onClick={onPaymentClicked} />
        )}
      </StatusDiv>
      <StyledDivider />
      <QuickActionMenuDiv>
        {viewData.bottomActionBar?.infoButtonText && (
          <StyledButton
            variant="text"
            onClick={onInfoClicked}
            startIcon={<InfoIcon />}
          >
            {viewData.bottomActionBar.infoButtonText}
          </StyledButton>
        )}
        {viewData.bottomActionBar?.paymentsButtonText && (
          <StyledButton
            variant="text"
            onClick={onPaymentClicked}
            startIcon={<PaymentsOutlinedIcon />}
          >
            {viewData.bottomActionBar.paymentsButtonText}
          </StyledButton>
        )}
        {viewData.bottomActionBar?.convertToInvoiceButtonText && (
          <StyledButton
            variant="text"
            onClick={onConvertToInvoiceClicked}
            startIcon={<FileCopyOutlinedIcon />}
          >
            {viewData.bottomActionBar?.convertToInvoiceButtonText}
          </StyledButton>
        )}
        {viewData.bottomActionBar?.sendButtonText && (
          <StyledButton
            onClick={onSendClicked}
            variant="contained"
            startIcon={<SendIcon />}
          >
            {viewData.bottomActionBar.sendButtonText}
          </StyledButton>
        )}
      </QuickActionMenuDiv>
    </StyledPaper>
  )
}

export default DocumentPreviewToolbar
