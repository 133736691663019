import { Box, ButtonBase, Divider, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { styled } from "@mui/system"
import { Timeline, timelineItemClasses } from "@mui/lab"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useDocumentInfoPreviewViewData from "model/documents/info/useDocumentInfoPreviewViewData"
import paths from "model/utils/paths"
import TextWithFallbackView from "components/views/generic/TextWithMobileFallbackView"
import DocumentInfoPreviewHistoryItemView from "components/views/documents/info/DocumentInfoPreviewHistoryItemView"

const StyledBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
}))

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledTimeline = styled(Timeline)({
  padding: 0,
  marginTop: 0,
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
})

const DocumentInfoPreviewDialog = ({
  teamKey,
  documentKey,
  onResendClicked,
  isOpen,
  onClose,
}: {
  teamKey: string
  documentKey: string | undefined
  onResendClicked: (resendWorkflowKey: string) => void
  isOpen: boolean
  onClose: () => void
}) => {
  const viewData = useDocumentInfoPreviewViewData({ teamKey, documentKey })
  if (!viewData) return null
  const historyItems = viewData.history?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onClose}
      title={viewData.title}
    >
      <StyledBox>
        <StyledContainer>
          <Typography color="secondary">{viewData.projectLabel}</Typography>
          <ButtonBase
            component={Link}
            to={paths.project(teamKey, viewData.projectKey || "")}
            disabled={!viewData.projectKey}
          >
            <TextWithFallbackView data={viewData.projectName} />
          </ButtonBase>
          <Typography color="secondary">{viewData.clientLabel}</Typography>
          <ButtonBase
            component={Link}
            to={paths.clientEdit(teamKey, viewData.clientKey || "")}
            disabled={!viewData.clientKey}
          >
            <TextWithFallbackView data={viewData.clientName} />
          </ButtonBase>
        </StyledContainer>
        <StyledDivider />
        <Typography variant="h6">{viewData.historyHeader}</Typography>
        <StyledTimeline>
          {historyItems.map((history) => {
            return (
              <DocumentInfoPreviewHistoryItemView
                key={history.viewKey}
                history={history}
                onResendClicked={onResendClicked}
              />
            )
          })}
        </StyledTimeline>
      </StyledBox>
    </NavigationBarDialog>
  )
}

export default DocumentInfoPreviewDialog
