import { useEffect, useState } from "react"

import * as shared from "probuild-shared"

import useAppUser from "model/utils/useAppUser"
import useDependencyProvider from "model/utils/useDependencyProvider"

function useDocumentInfoPreviewViewData({
  teamKey,
  documentKey,
}: {
  teamKey: string
  documentKey: string | undefined
}): shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentInfoPreviewViewData | null {
  const [viewData, setViewData] =
    useState<shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentInfoPreviewViewData | null>(
      null
    )
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  useEffect(() => {
    if (dependencyProvider && appUser && documentKey) {
      console.log(
        `Creating document info preview presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} documentKey = ${documentKey}`
      )
      const presenter =
        new shared.com.probuildsoftware.probuild.library.documents.DocumentInfoPreviewPresenter(
          dependencyProvider,
          appUser,
          {
            documentKey,
          }
        )
      const cleanUpViewData = presenter.observeViewData((updatedViewData) =>
        setViewData(updatedViewData)
      )
      return () => {
        cleanUpViewData()
      }
    }
  }, [appUser, dependencyProvider, documentKey])
  return viewData
}

export default useDocumentInfoPreviewViewData
