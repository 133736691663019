import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

const useSupportAgentPaywall = (): [
  shared.com.probuildsoftware.probuild.library.paywall.data.view.SupportAgentPaywallViewData | null,
  shared.com.probuildsoftware.probuild.library.paywall.SupportAgentPaywallInput | null
] => {
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider) {
      console.log(`Creating support agent paywall presenter`)
      return new shared.com.probuildsoftware.probuild.library.paywall.SupportAgentPaywallPresenter(
        dependencyProvider
      )
    }
  }, [dependencyProvider])
  return usePresenter(presenter)
}

export default useSupportAgentPaywall
