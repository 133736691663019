import {
  Box,
  Button,
  Chip,
  Divider,
  ListItemButton,
  styled,
} from "@mui/material"

import * as shared from "probuild-shared"

import TextWithFallbackView from "components/views/generic/TextWithFallbackView"
import { Link } from "react-router-dom"

const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
})

const LeftInnerColumn = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
})

const DocumentTypeAndDetailsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: theme.spacing(1),
  gap: theme.spacing(1),
}))

const RightInnerColumn = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
  justifyContent: "space-between",
  margin: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  gap: theme.spacing(1),
}))

const DocumentStatusBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: theme.spacing(0.5),
  gap: theme.spacing(0.5),
}))

const DocumentTypeText = styled(Box)({
  fontWeight: "bold",
})

const DocumentsListItem = ({
  teamKey,
  item,
  onClick,
  documentLink,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.documents.data.view.list.DocumentsListItemViewData
  onClick?: () => void
  documentLink?: string
}) => {
  return (
    <>
      <ListItemButton
        component={documentLink ? Link : Button}
        key={item.viewKey}
        to={documentLink}
        onClick={onClick}
        sx={{ width: "100%" }}
      >
        <StyledBox>
          <LeftInnerColumn>
            <DocumentTypeAndDetailsBox>
              <DocumentTypeText>{item.documentName}</DocumentTypeText>
              <TextWithFallbackView data={item.project} />
              <TextWithFallbackView data={item.client} />
            </DocumentTypeAndDetailsBox>
            <DocumentStatusBox>
              <Chip
                size="small"
                label={item.sendStatus}
                sx={{ cursor: "pointer" }}
              />
              {item.paymentStatus && (
                <Chip
                  size="small"
                  label={item.paymentStatus}
                  sx={{ cursor: "pointer" }}
                />
              )}
            </DocumentStatusBox>
          </LeftInnerColumn>
          <RightInnerColumn>
            <Box>{item.createdAtFormatted}</Box>
            <Box>{item.total}</Box>
          </RightInnerColumn>
        </StyledBox>
      </ListItemButton>
      <Divider />
    </>
  )
}

export default DocumentsListItem
