import { styled } from "@mui/material/styles"
import { Button, Typography } from "@mui/material"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import WebIcon from "@mui/icons-material/Web"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import useHelpAndFeedback from "model/help/useHelpAndFeedback"

const BodyTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}))

const HelpOptionsContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "fit-content",
    margin: "0 auto",
  },
}))

const HelpOptionsItemDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: theme.palette.primary.light,
  minWidth: "max-content",
  "& > *": {
    margin: theme.spacing(1),
    flexGrow: 1,
    textTransform: "none",
  },
}))

const HelpAndFeedbackPage = ({ teamKey }: { teamKey: string }) => {
  const viewData = useHelpAndFeedback({ teamKey })
  return (
    <TeamPageLayout title={viewData?.title} showLoadingSpinner={!viewData}>
      {viewData && (
        <>
          <BodyTypography>{viewData.body}</BodyTypography>
          <HelpOptionsContainerDiv>
            <Button variant="outlined" href={viewData.phoneButtonActionUrl}>
              <HelpOptionsItemDiv>
                <PhoneIcon fontSize="large" />
                <Typography>{viewData.phoneButtonText}</Typography>
              </HelpOptionsItemDiv>
            </Button>
            <Button variant="outlined" href={viewData.emailButtonActionUrl}>
              <HelpOptionsItemDiv>
                <EmailIcon fontSize="large" />
                <Typography>{viewData.emailButtonText}</Typography>
              </HelpOptionsItemDiv>
            </Button>
            <Button variant="outlined" href={viewData.websiteButtonActionUrl}>
              <HelpOptionsItemDiv>
                <WebIcon fontSize="large" />
                <Typography>{viewData.websiteButtonText}</Typography>
              </HelpOptionsItemDiv>
            </Button>
          </HelpOptionsContainerDiv>
        </>
      )}
    </TeamPageLayout>
  )
}

export default HelpAndFeedbackPage
