import { styled } from "@mui/material/styles"
import { Box, Divider, ListItemButton, Typography } from "@mui/material"
import { Check } from "@mui/icons-material"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import UserAvatar from "components/views/users/UserAvatar"
import { Link } from "react-router-dom"

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
}))

const TextContainerDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}))

const StyledContentDiv = styled("div")({
  display: "flex",
  alignItems: "center",
})

const UserListItemContent = ({
  teamKey,
  item,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.users.data.view.UserListItemViewData
}) => {
  return (
    <StyledBox>
      <StyledContentDiv>
        <UserAvatar size={50} viewData={item.avatar} />
        <TextContainerDiv>
          <Box fontStyle={item.isNameItalicized ? "italic" : "normal"}>
            {item.name}
          </Box>
          <Typography color="textSecondary">{item.phoneNumber}</Typography>
        </TextContainerDiv>
      </StyledContentDiv>
      {item.isSelected && <Check color="primary" />}
    </StyledBox>
  )
}

const UserListItemView = ({
  teamKey,
  item,
  onUserClicked,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.users.data.view.UserListItemViewData
  onUserClicked?: (userKey: string) => void
}) => {
  if (onUserClicked) {
    return (
      <>
        <ListItemButton
          onClick={() => {
            onUserClicked(item.userKey)
          }}
        >
          <UserListItemContent teamKey={teamKey} item={item} />
        </ListItemButton>
        <Divider />
      </>
    )
  } else {
    const userPath = paths.userEdit(teamKey, item.userKey)
    return (
      <>
        <ListItemButton component={Link} to={userPath}>
          <UserListItemContent teamKey={teamKey} item={item} />
        </ListItemButton>
        <Divider />
      </>
    )
  }
}

export default UserListItemView
