import { List, Paper, Typography } from "@mui/material"

import * as shared from "probuild-shared"

import UserListItemView from "./UserListItemView"

const UserListSectionView = ({
  teamKey,
  section,
  onUserClicked,
}: {
  teamKey: string
  section: shared.com.probuildsoftware.probuild.library.users.data.view.UserListSectionViewData
  onUserClicked?: (userKey: string) => void
}) => {
  const users = section.users?.asJsReadonlyArrayView()
  return (
    <div>
      <Typography sx={{ ml: 2, mb: 2 }}>{section.title}</Typography>
      <List component={Paper} disablePadding={true} sx={{ mb: 3 }}>
        {users.map((userItem) => (
          <UserListItemView
            teamKey={teamKey}
            item={userItem}
            onUserClicked={onUserClicked}
          />
        ))}
      </List>
    </div>
  )
}

export default UserListSectionView
