import { Button } from "@mui/material"

import * as shared from "probuild-shared"

const RepeatItem = ({
  documentAnswerKey,
  userKey,
  element,
  input,
}: {
  documentAnswerKey: string
  userKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  const onClick = () => {
    input.addRepeatItem(documentAnswerKey, element.elementKey, userKey)
  }
  return (
    <Button onClick={onClick} variant="contained" color="primary">
      {element.repeatItem?.addButtonText}
    </Button>
  )
}

export default RepeatItem
