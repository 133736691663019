import { ListItemButton, TableCell, styled } from "@mui/material"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import TextWithFallbackView from "components/views/generic/TextWithFallbackView"
import LinkTableRow from "components/views/generic/LinkTableRow"

const StyledContentDiv = styled("div")({
  display: "flex",
  flexBasis: "100%",
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  display: "flex",
  flex: 1,
  overflow: "hidden",
  fontSize: 14,
  [theme.breakpoints.down("lg")]: { fontSize: 16 },
}))

const ClientListItemView = ({
  teamKey,
  item,
  isSmallScreen,
  isMediumScreen,
  isLargeScreen,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.clients.data.view.ClientListItemViewData
  isSmallScreen: boolean
  isMediumScreen: boolean
  isLargeScreen: boolean
}) => {
  const clientPath = paths.clientEdit(teamKey, item.clientKey)

  return (
    <ListItemButton component={LinkTableRow} to={clientPath}>
      <StyledContentDiv>
        <StyledTableCell>{item.fullName}</StyledTableCell>
        {isSmallScreen && (
          <StyledTableCell>
            <TextWithFallbackView data={item.email} />
          </StyledTableCell>
        )}
        {isMediumScreen && (
          <StyledTableCell>
            <TextWithFallbackView data={item.mobilePhone} />
          </StyledTableCell>
        )}
        {isLargeScreen && (
          <StyledTableCell>
            <TextWithFallbackView data={item.homePhone} />
          </StyledTableCell>
        )}
      </StyledContentDiv>
    </ListItemButton>
  )
}

export default ClientListItemView
