import { useCallback, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import * as shared from "probuild-shared"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import DocumentReportView from "components/views/documents/DocumentReportView"
import paths from "model/utils/paths"
import EmailComposerDialog from "components/dialogs/EmailComposerDialog"
import DocumentPreviewToolbar from "components/views/documents/preview/DocumentPreviewToolbar"
import OverflowMenu from "components/views/generic/OverflowMenu"
import useSnackbar from "model/snackbar/useSnackbar"
import PaymentListDialog from "components/dialogs/PaymentListDialog"
import PaymentEditDialog from "components/dialogs/PaymentEditDialog"
import useUserKey from "model/utils/useUserKey"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import EditButton from "components/views/generic/EditButton"
import DocumentInfoPreviewDialog from "components/dialogs/DocumentInfoPreviewDialog"
import useDocumentPreviewViewData from "model/documents/preview/useDocumentPreviewViewData"

const DocumentReportPage = ({ teamKey }: { teamKey: string }) => {
  const { documentKey } = useParams()
  const location = useLocation()
  const [isPaymentsDialogOpen, setIsPaymentsDialogOpen] =
    useState<boolean>(false)
  const [paymentKeyForOpenDialog, setPaymentKeyForOpenDialog] = useState<
    string | null | undefined
  >(undefined)
  const userKey = useUserKey(teamKey)
  const showSnackbar = useSnackbar()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const isEmailComposerDialogOpen =
    location.state?.isEmailComposerDialogOpen === true
  const resendWorkflowKey = location.state?.resendWorkflowKey
  const setIsEmailComposerDialogOpen = useCallback(
    (updatedIsOpen: boolean, resendWorkflowKey?: string) => {
      if (updatedIsOpen) {
        navigate(location.pathname, {
          state: {
            isEmailComposerDialogOpen: updatedIsOpen,
            resendWorkflowKey: resendWorkflowKey,
          },
        })
      } else {
        navigate(-1)
      }
    },
    [location, navigate]
  )
  const isInfoDialogOpen = location.state?.isInfoDialogOpen === true
  const setIsInfoDialogOpen = useCallback(
    (updatedIsOpen: boolean) => {
      if (updatedIsOpen) {
        navigate(location.pathname, {
          state: {
            isInfoDialogOpen: updatedIsOpen,
          },
        })
      } else {
        navigate(-1)
      }
    },
    [location, navigate]
  )
  const observer = useMemo(() => {
    return {
      onNavigateBack() {
        navigate(-1)
      },
      onWorkflowCreatedEvent() {},
      onDeleteError(message: string) {
        showSnackbar(message, "error")
      },
      onDeleteSuccess(message: string) {
        showSnackbar(message, "success")
        navigate(-1)
      },
      onCopyCreated(
        metadata: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentMetadataViewData
      ) {
        navigate(paths.document(teamKey, metadata.documentAnswerKey))
      },
      onConversionToInvoice(
        metadata: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentMetadataViewData
      ) {
        navigate(paths.document(teamKey, metadata.documentAnswerKey))
      },
      onPrintInitiated() {},
      onSharePdf() {},
      onLaunchPayments() {
        setIsPaymentsDialogOpen(true)
      },
    }
  }, [navigate, teamKey, showSnackbar])
  const [viewData, input] = useDocumentPreviewViewData({
    teamKey,
    documentKey,
    observer,
  })
  const onSendClicked = () => {
    setIsEmailComposerDialogOpen(true)
  }
  const onInfoClicked = () => {
    setIsInfoDialogOpen(true)
  }
  const onPaymentClicked = () => {
    setIsPaymentsDialogOpen(true)
  }
  const onCloseEmailComposerDialog = useCallback(
    () => setIsEmailComposerDialogOpen(false),
    [setIsEmailComposerDialogOpen]
  )
  const onCloseInfoDialog = useCallback(
    () => setIsInfoDialogOpen(false),
    [setIsInfoDialogOpen]
  )
  const onClosePaymentEditDialog = useCallback(() => {
    setPaymentKeyForOpenDialog(undefined)
  }, [])
  return (
    <TeamPageLayout
      title={viewData?.title}
      barItems={
        <>
          {viewData?.editButtonText && documentKey && (
            <EditButton
              text={viewData?.editButtonText}
              variant="outlined"
              to={paths.documentEdit(teamKey, documentKey)}
            />
          )}
          {viewData?.menu && (
            <OverflowMenu
              viewData={viewData?.menu}
              onActionSelected={(actionKey) => {
                if (!userKey) return
                input?.onDocumentOverflowMenuAction(actionKey, userKey)
              }}
            />
          )}
        </>
      }
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          <DocumentReportView reportData={viewData.report} />
          <DocumentPreviewToolbar
            viewData={viewData}
            onInfoClicked={onInfoClicked}
            onPaymentClicked={onPaymentClicked}
            onConvertToInvoiceClicked={() => {
              if (userKey) {
                input?.convertToInvoice(userKey)
              }
            }}
            onSendClicked={onSendClicked}
          />
          {isEmailComposerDialogOpen && documentKey && (
            <EmailComposerDialog
              teamKey={teamKey}
              documentKey={documentKey}
              resendWorkflowKey={resendWorkflowKey}
              isOpen={isEmailComposerDialogOpen}
              onClose={onCloseEmailComposerDialog}
            />
          )}
          {isInfoDialogOpen && !isEmailComposerDialogOpen && (
            <DocumentInfoPreviewDialog
              teamKey={teamKey}
              documentKey={documentKey}
              onResendClicked={(resendWorkflowKey: string) => {
                setIsEmailComposerDialogOpen(true, resendWorkflowKey)
              }}
              isOpen={isInfoDialogOpen}
              onClose={onCloseInfoDialog}
            />
          )}
          {isPaymentsDialogOpen && (
            <PaymentListDialog
              teamKey={teamKey}
              documentKey={documentKey}
              isOpen={isPaymentsDialogOpen}
              onPaymentSelected={(paymentKey: string) => {
                setPaymentKeyForOpenDialog(paymentKey)
              }}
              onCreateClicked={() => {
                setPaymentKeyForOpenDialog(null)
              }}
              onClose={() => {
                setIsPaymentsDialogOpen(false)
              }}
            />
          )}
          <ConfirmationDialog
            open={viewData.deleteConfirmationDialog != null}
            onConfirm={() => input?.deleteDocumentConfirmed()}
            onCancel={() => input?.deleteDocumentCancelled()}
            dialogViewData={viewData.deleteConfirmationDialog}
          />
          {(paymentKeyForOpenDialog || paymentKeyForOpenDialog === null) && (
            <PaymentEditDialog
              teamKey={teamKey}
              documentKey={documentKey}
              paymentKey={paymentKeyForOpenDialog}
              isOpen={true}
              navigateSafeState={navigateSafeState}
              onCloseDialog={onClosePaymentEditDialog}
            />
          )}
        </>
      )}
    </TeamPageLayout>
  )
}

export default DocumentReportPage
