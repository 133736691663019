import { Grid } from "@mui/material"

import * as shared from "probuild-shared"

import DocumentEditingGroup from "components/views/documents/editing/DocumentEditingGroup"

const DocumentEditingView = ({
  teamKey,
  userKey,
  viewData,
  input,
}: {
  teamKey: string
  userKey: string | null
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentEditViewData | null
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput | null
}) => {
  return (
    <>
      {viewData && input && userKey && (
        <DocumentEditingLoadedView
          teamKey={teamKey}
          userKey={userKey}
          viewData={viewData}
          input={input}
        />
      )}
    </>
  )
}

const DocumentEditingLoadedView = ({
  teamKey,
  userKey,
  viewData,
  input,
}: {
  teamKey: string
  userKey: string
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentEditViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  const groups = createGroups(viewData)
  return (
    <Grid container>
      {viewData &&
        input &&
        groups.map((group) => {
          return (
            <DocumentEditingGroup
              teamKey={teamKey}
              userKey={userKey}
              key={group.groupElement?.viewKey || ""}
              isFirstGroup={group === groups[0]}
              documentAnswerKey={viewData.info.documentAnswerKey}
              groupElement={group.groupElement}
              elements={group.elements}
              input={input}
            />
          )
        })}
    </Grid>
  )
}

interface Group {
  groupElement: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData | null
  elements: Array<shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData>
}

const createGroups = (
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentEditViewData | null
): Array<Group> => {
  const groups: Array<Group> = []
  if (!viewData) {
    return groups
  }
  const types =
    shared.com.probuildsoftware.probuild.library.documents.data.types.Types
  let currentGroup: Group = {
    groupElement: null,
    elements:
      new Array<shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData>(),
  }
  viewData.elements.asJsReadonlyArrayView().forEach((element) => {
    if (element.type === types.SNAPSHOT_TYPE_GROUP) {
      groups.push(currentGroup)
      currentGroup = {
        groupElement: element,
        elements:
          new Array<shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData>(),
      }
    } else {
      currentGroup.elements.push(element)
    }
  })
  if (currentGroup !== null) {
    groups.push(currentGroup)
  }
  return groups
}

export default DocumentEditingView
