import useAppUser from "model/utils/useAppUser"
import useDependencyProvider from "model/utils/useDependencyProvider"
import { useMemo } from "react"

import * as shared from "probuild-shared"
import usePresenter from "model/presenter/usePresenter"

function useDocumentEditViewData({
  teamKey,
  documentKey,
  documentType,
  observer,
}: {
  teamKey: string
  documentKey: string | undefined
  documentType: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.documents.DocumentEditEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentEditViewData | null,
  shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput | null
] {
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const documentDefinitionKey = determineDocumentDefinitionKey(documentType)
  const presenter = useMemo(() => {
    if (
      appUser &&
      dependencyProvider &&
      (documentKey || documentDefinitionKey)
    ) {
      console.log(
        `Creating document edit presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} documentKey = ${documentKey} documentDefinitionKey = ${documentDefinitionKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.documents.DocumentEditPresenter(
        dependencyProvider,
        null,
        appUser,
        {
          projectKey: null,
          documentDefinitionKey,
          documentKey,
          restoreStateJson: null,
        }
      )
    }
  }, [appUser, dependencyProvider, documentKey, documentDefinitionKey])
  return usePresenter(presenter, observer)
}

function determineDocumentDefinitionKey(
  documentType: string | undefined
): string | null {
  const Companion =
    shared.com.probuildsoftware.probuild.library.documents.DocumentEditPresenter
      .Companion
  if (documentType === "estimate") {
    return Companion.DEFAULT_ESTIMATE_DEFINITION_KEY
  } else if (documentType === "invoice") {
    return Companion.DEFAULT_INVOICE_DEFINITION_KEY
  }
  return null
}

export default useDocumentEditViewData
