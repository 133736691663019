import { useMemo } from "react"
import { styled } from "@mui/material/styles"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import LabeledSwitchView from "components/views/generic/LabeledSwitchView"
import LabeledCard from "components/views/generic/LabeledCard"
import useEmailComposerViewData from "model/documents/email/useEmailComposerViewData"
import useSnackbar from "model/snackbar/useSnackbar"
import { Link } from "react-router-dom"
import paths from "model/utils/paths"

const StyledDialogContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}))

const EmailComposerDialog = ({
  teamKey,
  documentKey,
  resendWorkflowKey,
  isOpen,
  onClose,
}: {
  teamKey: string
  documentKey: string
  resendWorkflowKey: string | undefined
  isOpen: boolean
  onClose: () => void
}) => {
  const showSnackbar = useSnackbar()
  const observer = useMemo(() => {
    return {
      onSendSuccess() {
        onClose()
      },
      onSendError(message: string) {
        showSnackbar(message, "error")
      },
    }
  }, [onClose, showSnackbar])
  const [viewData, input] = useEmailComposerViewData({
    teamKey,
    documentKey,
    resendWorkflowKey,
    observer,
  })
  if (!viewData || !input) return null
  if (viewData.fromEmailMissingDialog) {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle id="confirmation-dialog-title">
          {viewData.fromEmailMissingDialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            {viewData.fromEmailMissingDialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            component={Link}
            state={{
              focusOnEmail: true,
              afterSaveNavigateTo: paths.document(teamKey, documentKey),
              afterSaveNavigateState: {
                isEmailComposerDialogOpen: true,
              },
            }}
            to={paths.profile(teamKey)}
            variant="contained"
            color="primary"
          >
            {viewData.fromEmailMissingDialog.cancelButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  return (
    <>
      <NavigationBarDialog
        isOpen={isOpen}
        onClose={onClose}
        title={viewData.title}
        dynamicHeight
        barItems={
          <Button
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            onClick={() => input.send(true)}
          >
            {viewData.sendButtonText}
          </Button>
        }
      >
        <StyledDialogContent>
          <LabeledCard text={""}>
            <ViewDataTextField
              viewData={viewData.toEmailAddress}
              onChange={(text: string) => input.changeTo(text)}
            />
            <LabeledSwitchView
              labelText={viewData.sendCopyToBusinessEmail.label}
              isOn={viewData.sendCopyToBusinessEmail.value}
              onChange={(newValue: boolean) =>
                input.changeSendCopyToBusinessEmail(newValue)
              }
            />
            <ViewDataTextField
              viewData={viewData.fromEmailAddress}
              onChange={(text: string) => {}}
            />
            <ViewDataTextField
              viewData={viewData.subject}
              onChange={(text: string) => input.changeSubject(text)}
            />
            <ViewDataTextField
              viewData={viewData.message}
              onChange={(text: string) => input.changeMessage(text)}
            />
          </LabeledCard>
          <StyledPaper>
            <PictureAsPdfIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">
              {viewData.attachmentFilename}
            </Typography>
          </StyledPaper>
        </StyledDialogContent>
      </NavigationBarDialog>
    </>
  )
}

export default EmailComposerDialog
