import ContactsIcon from "@mui/icons-material/Contacts"
import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import SearchBar from "components/views/generic/SearchBar"
import ListDialogContent from "components/dialogs/ListDialogContent"
import useDocumentAllList from "model/documents/list/useDocumentAllList"
import DocumentsListItem from "components/views/documents/DocumentsListItemView"

const DocumentListDialog = ({
  teamKey,
  isOpen,
  onDocumentSelected,
}: {
  teamKey: string
  isOpen: boolean
  onDocumentSelected: (documentKey: string | null) => void
}) => {
  const [viewData, input] = useDocumentAllList({ teamKey, isSelecting: true })
  const documentListItems = viewData?.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={() => {
        onDocumentSelected(null)
        input?.changeSearchQuery("")
      }}
      title={viewData?.title}
      hideTitleOnMobile
      barItems={
        <SearchBar
          placeholder={viewData?.status.searchPlaceholder}
          onChange={(text: string) => {
            input?.changeSearchQuery(text)
          }}
          autoFocus={true}
        />
      }
    >
      <ListDialogContent
        emptyViewData={viewData?.status.empty}
        emptyIcon={ContactsIcon}
      >
        {documentListItems?.map((listItem) => {
          return (
            <DocumentsListItem
              teamKey={teamKey}
              item={listItem}
              onClick={() => {
                onDocumentSelected(listItem.viewKey)
              }}
            />
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

export default DocumentListDialog
