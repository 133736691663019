import { Box, List, Paper } from "@mui/material"
import { useCallback } from "react"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import ProjectListItemView from "components/views/projects/list/ProjectListItemView"
import SearchBar from "components/views/generic/SearchBar"
import LoadingView from "components/views/LoadingView"
import useProjectList from "model/projects/useProjectList"
import EmptyView from "components/views/EmptyView"
import Dashboard from "@mui/icons-material/Dashboard"

const ProjectListPage = ({ teamKey }: { teamKey: string }) => {
  const [viewData, input] = useProjectList({ teamKey })
  const loadMoreAtBottom = useCallback(() => {
    input?.loadMoreAtBottom()
  }, [input])
  if (!viewData || !input) return <></>
  const projectListItems = viewData?.items?.asJsReadonlyArrayView() || []
  const hasMore = viewData?.hasMoreAtBottom === true
  return (
    <TeamPageLayout
      title={viewData?.title}
      barItems={
        <SearchBar
          placeholder={viewData?.searchPlaceholder}
          onChange={(text) => input?.changeSearchQuery(text)}
        />
      }
      loadMoreAtBottom={loadMoreAtBottom}
      hasMore={hasMore}
      showLoadingSpinner={!viewData}
    >
      <List component={Paper} disablePadding={true}>
        {projectListItems.map((item) => {
          return (
            <ProjectListItemView teamKey={teamKey} projectListItem={item} />
          )
        })}
        {viewData.empty && (
          <Box sx={{ p: 2 }}>
            <EmptyView icon={Dashboard} viewData={viewData.empty} />
          </Box>
        )}
      </List>
      {viewData.isLoadingAtBottom === true && <LoadingView key={0} />}
    </TeamPageLayout>
  )
}

export default ProjectListPage
