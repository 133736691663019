import React, { useCallback, useState } from "react"

import * as shared from "probuild-shared"

import ItemEditDialog from "components/dialogs/ItemEditDialog"
import ItemListDialog from "components/dialogs/ItemListDialog"

const DocumentEditingLineItemDialogs = ({
  teamKey,
  rowDocumentKey,
  input,
  isSelectingLineItem,
  setIsSelectingLineItem,
}: {
  teamKey: string
  rowDocumentKey: string
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
  isSelectingLineItem: boolean
  setIsSelectingLineItem: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [isCreatingLineItem, setIsCreatingLineItem] = useState<boolean>(false)
  const onItemSaved = useCallback(
    (itemKey: string | null) => {
      setIsCreatingLineItem(false)
      if (itemKey) {
        input.loadItem(rowDocumentKey, itemKey)
      }
    },
    [input, rowDocumentKey]
  )
  const onCreateItemClicked = () => {
    setIsCreatingLineItem(true)
    setIsSelectingLineItem(false)
  }
  const onItemSelected = (itemKey: string | null) => {
    if (itemKey) {
      input.loadItem(rowDocumentKey, itemKey)
    }
    setIsSelectingLineItem(false)
  }
  return (
    <>
      {isSelectingLineItem && (
        <ItemListDialog
          teamKey={teamKey}
          isOpen={isSelectingLineItem}
          onItemSelected={onItemSelected}
          onCreateClicked={onCreateItemClicked}
        />
      )}
      {isCreatingLineItem && (
        <ItemEditDialog
          teamKey={teamKey}
          itemKey={undefined}
          isOpen={isCreatingLineItem}
          onItemSaved={onItemSaved}
        />
      )}
    </>
  )
}

export default DocumentEditingLineItemDialogs
