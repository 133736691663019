import { useCallback, useState } from "react"
import { styled } from "@mui/material/styles"
import { Button, Typography, Box, ButtonBase } from "@mui/material"
import ContactsIcon from "@mui/icons-material/Contacts"

import * as shared from "probuild-shared"

import ClientListDialog from "components/dialogs/ClientListDialog"
import ClientEditDialog from "components/dialogs/ClientEditDialog"

const CurrentClientButtonContentDiv = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const Client = ({
  teamKey,
  documentAnswerKey,
  element,
  input,
}: {
  teamKey: string
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  const {
    clientName,
    clientKey,
    detailText,
    changeButtonText,
    removeButtonText,
  } = element.client || {}

  const [isSelectingClient, setIsSelectingClient] = useState<boolean>(false)
  const [isEditingClient, setIsEditingClient] = useState<boolean>(false)
  const [isCreatingClient, setIsCreatingClient] = useState<boolean>(false)
  const onAddClientClicked = () => {
    setIsSelectingClient(true)
  }
  const onSave = useCallback(
    (clientKey: string) => {
      input.changeClient(documentAnswerKey, clientKey)
    },
    [documentAnswerKey, input]
  )
  const onCloseClientDialog = useCallback(() => {
    setIsEditingClient(false)
    setIsCreatingClient(false)
  }, [])
  const onRemoveButtonClicked = () =>
    input.changeClient(documentAnswerKey, null)
  const onClientSelected = (clientKey: string | null) => {
    setIsSelectingClient(false)
    if (clientKey) {
      input.changeClient(documentAnswerKey, clientKey)
    }
  }
  const onCreateClientClicked = () => {
    setIsCreatingClient(true)
    setIsSelectingClient(false)
  }
  return (
    <>
      {clientKey && (
        <ButtonBase
          sx={{ width: "100%", mb: 1, py: 1 }}
          onClick={() => {
            setIsEditingClient(true)
          }}
        >
          <CurrentClientButtonContentDiv>
            {clientName && (
              <Typography display="inline" variant="body1">
                {clientName}
              </Typography>
            )}
            <ContactsIcon color="primary" />
          </CurrentClientButtonContentDiv>
        </ButtonBase>
      )}
      {detailText && (
        <Box display="inline" fontStyle="italic">
          {detailText}
        </Box>
      )}
      {changeButtonText && (
        <Button
          variant="contained"
          color="primary"
          onClick={onAddClientClicked}
        >
          {changeButtonText}
        </Button>
      )}
      {removeButtonText && (
        <Button sx={{ ml: 1 }} color="primary" onClick={onRemoveButtonClicked}>
          {removeButtonText}
        </Button>
      )}
      {isSelectingClient && (
        <ClientListDialog
          teamKey={teamKey}
          isOpen={isSelectingClient}
          onClientSelected={onClientSelected}
          onCreateClicked={onCreateClientClicked}
        />
      )}
      {(isEditingClient || isCreatingClient) && (
        <ClientEditDialog
          teamKey={teamKey}
          clientKey={!isCreatingClient && clientKey ? clientKey : undefined}
          isOpen={isEditingClient || isCreatingClient}
          onCloseDialog={onCloseClientDialog}
          onSave={onSave}
        />
      )}
    </>
  )
}

export default Client
