import { Box, ListItemButton, ListItemText } from "@mui/material"
import ContactsIcon from "@mui/icons-material/Contacts"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useClientList from "model/clients/list/useClientList"
import SearchBar from "components/views/generic/SearchBar"
import CreateButton from "components/views/generic/CreateButton"
import ListDialogContent from "components/dialogs/ListDialogContent"

const ClientListDialog = ({
  teamKey,
  isOpen,
  onClientSelected,
  onCreateClicked,
}: {
  teamKey: string
  isOpen: boolean
  onClientSelected: (clientKey: string | null) => void
  onCreateClicked: () => void
}) => {
  const [viewData, input] = useClientList({
    teamKey,
    isSelecting: true,
  })
  const clientListItems = viewData?.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={() => {
        onClientSelected(null)
        input?.changeSearchQuery("")
      }}
      title={viewData?.title}
      hideTitleOnMobile
      barItems={
        <>
          <SearchBar
            placeholder={viewData?.searchHint}
            onChange={(text: string) => {
              input?.changeSearchQuery(text)
            }}
            autoFocus={true}
          />
          <CreateButton
            text={viewData?.createButtonText}
            onClick={onCreateClicked}
          />
        </>
      }
    >
      <ListDialogContent
        emptyViewData={viewData?.empty}
        emptyIcon={ContactsIcon}
      >
        {clientListItems?.map((listItem) => {
          return (
            <ListItemButton
              key={listItem.clientKey}
              divider={true}
              onClick={() => {
                onClientSelected(listItem.clientKey)
              }}
            >
              <Box px={1}>
                <ListItemText>{listItem.fullName}</ListItemText>
              </Box>
            </ListItemButton>
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

export default ClientListDialog
