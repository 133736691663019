import { useMemo } from "react"

import * as shared from "probuild-shared"

import useAppUser from "model/utils/useAppUser"
import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

function useDocumentPreviewViewData({
  teamKey,
  documentKey,
  observer,
}: {
  teamKey: string
  documentKey: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.documents.DocumentPreviewEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentPreviewViewData | null,
  shared.com.probuildsoftware.probuild.library.documents.DocumentPreviewInput | null
] {
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const presenter = useMemo(() => {
    if (dependencyProvider && documentKey && appUser) {
      console.log(
        `Creating document preview presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} documentKey = ${documentKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.documents.DocumentPreviewPresenter(
        dependencyProvider,
        appUser,
        {
          documentKey,
          isPrintSupported: false,
          isSharePdfSupported: false,
          pathTransformer: (path) =>
            `/api/storage/${path}?resize=600x600&jpegQuality=80&rotate=true&fit=inside`,
        }
      )
    }
  }, [appUser, documentKey, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default useDocumentPreviewViewData
