import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"

const EditButton = ({
  text,
  to,
  variant,
  onClick,
}: {
  text: string | null | undefined
  to?: string
  variant?: "text" | "outlined" | "contained" | undefined
  onClick?: () => void
}) => {
  return (
    <Button
      component={to ? Link : "button"}
      to={to}
      onClick={onClick}
      variant={variant}
      color="primary"
      sx={{ flexShrink: 0 }}
      startIcon={<EditIcon />}
    >
      {text}
    </Button>
  )
}

export default EditButton
